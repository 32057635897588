@import '../../utils/variables';

#apartaments {
	@media(max-width:1199px) {
		overflow-y: scroll;
	}

	@media(min-width:1199px) {
		overflow-x: inherit;
	}


	&::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}

	table {
		text-align: center;
		color: $secondary;

		@media (max-width:1200px) {
			min-width: 1180px;
		}

		tr td {
			@media (max-width: 767.98px) {
				white-space: nowrap;
			}
		}
	}

	thead th {
		font-weight: 900;
		transition: all 0.2s ease-in-out;

		&:hover {
			cursor: pointer;
			color: $primary;
		}
	}

	th {
		font-weight: 400;
		vertical-align: middle;
		text-transform: uppercase;

		&:nth-last-child(2) {
			font-weight: 900;
		}

	}

	td {
		vertical-align: middle;
		text-transform: uppercase;
		font-weight: 400;

		.btn {
			margin-top: 0 !important;
		}
	}
}


.apartament-cyan {
	background: $cyanSvg;
	color: $cyanSvg;
	width: 16px;
	height: 50px;

}

.apartament-magenta {
	background: $magentaSvg;
	color: $magentaSvg;
	width: 16px;
	height: 50px;

}

.apartament-orange {
	background: $orangeSvg;
	color: $orangeSvg;
	width: 16px;
	height: 50px;

}


.apartament-blue {
	background: $blueSvg;
	color: $blueSvg;
	width: 16px;
	height: 50px;

}


th {
	&.arrow-down {
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 50%;
			right: -0.3rem;
			width: 0;
			height: 0;
			transform: translateY(-50%);
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 6px solid $secondary;
		}

		&--top {
			&::before {
				top: 43%;
				transform: rotate(180deg);
				transform-origin: 44%;
			}
		}
	}

	&:first-child.arrow-down {
		&::before {
			left: 3.5rem;
		}
	}
}

.apartaments__icon {
	position: relative;
	right: 0.5rem;

}

.apartaments__icon-wrapper {

	&:hover .apartaments__tooltip {
		opacity: 1;
	}
}

.apartaments__tooltip {
	position: absolute;
	top: -7rem;
	left: -18rem;
	width: 200px;
	height: 60px;
	padding: 2rem 1rem;
	border: 1px solid rgba(0, 0, 0, 0.404);
	border-radius: 10px;
	box-shadow: 0px 0px 5px 0px #888787;
	z-index: 5;
	background-color: #fff;
	opacity: 0;
	transition: .3s ease;
}

.btn-arrow {
	position: relative;
	color: $primary;
	font-weight: 700;
	border: 0;
	padding: 0;

	&:focus {
		box-shadow: none;
	}

	@media (min-width:500px) {

		&:after,
		&:before {
			display: inline-block;
			margin-left: 2rem;
			margin-right: 2rem;
			content: "";
			background: url("../../../../../images/szablon/arrow-down.svg");
			width: 21px;
			height: 11px;
		}
	}

}
	@media (min-width:1600px) {
		#apartaments {
			position: relative;
			th:first-of-type {
				position: absolute;
				left:-170px;
			}
		}
		.category-title {
			&:after {
				content: "";
				position: absolute;
				top: 29px;
				right: 1px;
				width: 0;
				height: 0;
				border-top: 6px solid transparent;
				border-bottom: 6px solid transparent;
				border-left: 6px solid #888787;
				opacity: .4;
			}
		}

	}
	.category-title {
		width:150px;
		font-weight:bold;
		color:#7A6650;
		box-shadow:0px 0px 5px 0px #888787;
		height:50px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		&:after {
			content: "";
			position: absolute;
			top:22px;
			right: -7px;
			width: 0;
			height: 0;
			border-top: 6px solid transparent;
			border-bottom: 6px solid transparent;
			border-left: 6px solid #888787;
			opacity:.4;
		}
	}
